import React from 'react';
import { motion } from 'framer-motion';
import RowSet from '../grid/row-set';
import { borderRadius, breakpoints } from '../tokens';
import { colors } from '../colors';
import Typography from '../typography';
import StyledLink from '../styled-link';
import Link, { getLink, LinkWithTitle } from '../link';
import MediaItem, { MediaItemAsset } from '../media-item';
import AspectRatio from '../aspect-ratio';
import RightCaret from '../../structured-text/blocks/solutions-carousel/right-caret.svg';
import { useSliderContext } from '../../basic-slider';

interface HoverItemSetProps {
    cards: {
        title: string;
        text: string;
        link: LinkWithTitle
        media?: MediaItemAsset | null
    }[]
    link?: LinkWithTitle
    alignRight?: boolean
    setValue?: (index: number) => void
}

const HoverItemSet = ({
    cards,
    link,
    alignRight = false,
    setValue: setExternalValue,
}: HoverItemSetProps): JSX.Element => {
    const [value, setValue] = React.useState(0);
    const id = React.useId();

    const sliderCtx = useSliderContext();

    return (
        <div css={{
            paddingBottom: '70px',
            [breakpoints.mb]: {
                paddingBottom: '40px',
            },
        }}
        >
            <RowSet breakpoints={{ dt: { between: 24 }, tb: { between: 20 } }}>
                {cards.map((card, idx) => (
                    <div
                        role="group"
                        css={{
                            position: 'relative',
                            paddingTop: '20px',
                            paddingLeft: '20px',
                            paddingBottom: '20px',
                            paddingRight: '45px',
                            borderRadius: borderRadius.large,
                            transition: '0.3s',
                            userSelect: 'none',
                            [breakpoints.tb]: {
                                paddingRight: '20px',
                            },
                            [breakpoints.mb]: {
                                border: `1px solid ${colors.grey100}!important`,
                            },
                        }}
                        style={{
                            border: idx === value ? '1px solid transparent' : `1px solid ${colors.grey100}`,
                        }}
                        onMouseEnter={() => {
                            if (window.innerWidth > 767) {
                                setValue(idx);
                                if (setExternalValue) {
                                    setExternalValue(idx);
                                }
                                if (sliderCtx) {
                                    sliderCtx.jumpTo(idx);
                                }
                            }
                        }}
                    >

                        {value === idx && (
                            <motion.div
                                layoutId={id}
                                css={{
                                    position: 'absolute',
                                    inset: '0',
                                    boxShadow: '0 24px 54px 0 rgba(0, 0, 0, 0.09)',
                                    width: 'calc(100% + 2px)',
                                    left: '-1px',
                                    top: '-2px',
                                    height: 'calc(100% + 4px)',
                                    backgroundColor: colors.shadesWhite,
                                    [breakpoints.mb]: {
                                        display: 'none',
                                    },
                                }}
                                style={{
                                    borderRadius: borderRadius.large,
                                }}
                                transition={{
                                    type: 'spring',
                                    duration: 0.6,
                                }}
                            />
                        )}
                        <div css={{
                            position: 'relative',
                            zIndex: '1',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        >
                            <div css={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            >
                                {card?.media && card?.media.length > 0 && (
                                    <div css={{
                                        width: '74px',
                                        height: '74px',
                                        minWidth: '74px',
                                        minHeight: '74px',
                                        borderRadius: borderRadius.small,
                                        overflow: 'hidden',
                                        transform: 'translateZ(0)',
                                        marginRight: '23px',
                                        [breakpoints.mb]: {
                                            display: 'none',
                                        },
                                    }}
                                    >
                                        <AspectRatio breakpoints={{ dt: { width: 20, height: 20 } }}>
                                            <MediaItem
                                                css={{
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                                item={card?.media}
                                            />
                                        </AspectRatio>
                                    </div>
                                )}
                                <div>
                                    <Typography
                                        override
                                        fontSize={{
                                            dt: 1822,
                                            mb: 1627,
                                        }}
                                        css={{
                                            paddingBottom: '8px',
                                            [breakpoints.mb]: {
                                                paddingBottom: '2px',
                                            },
                                        }}
                                    >
                                        {card.title}
                                    </Typography>
                                    <Typography
                                        override
                                        fontSize={{
                                            dt: 1420,
                                            tb: 1322,
                                            mb: 1226,
                                        }}
                                        css={{
                                            opacity: 0.8,
                                        }}
                                    >
                                        {card.text}
                                    </Typography>
                                </div>
                            </div>
                            <div css={{
                                paddingLeft: '12px',
                                [breakpoints.tb]: {
                                    paddingLeft: '14px',
                                },
                            }}
                            >
                                {!alignRight && (card?.link && card.link.length > 0) && (
                                    <StyledLink
                                        to={getLink(card.link[0].link)}
                                        css={{
                                            whiteSpace: 'nowrap',
                                            transition: '0.4s',
                                            opacity: 0,
                                            '[role=group]:hover &': {
                                                opacity: 1,
                                            },
                                            [breakpoints.tb]: {
                                                display: 'none',
                                            },
                                        }}
                                    >
                                        {card.link[0].title}
                                    </StyledLink>
                                )}
                                <div css={alignRight ? {} : {
                                    display: 'none',
                                    [breakpoints.tb]: {
                                        display: 'block',
                                    },
                                }}
                                >
                                    <RightCaret />
                                </div>
                            </div>
                        </div>
                        {(card?.link && card.link.length > 0) && (
                            <Link
                                aria-hidden="true"
                                to={getLink(card.link[0].link)}
                                css={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    top: '0',
                                    left: '0',
                                    zIndex: 1,
                                }}
                            />
                        )}
                    </div>
                ))}
            </RowSet>
            {(link && link.length > 0) && (
                <div css={{
                    paddingTop: '26px',
                    textAlign: alignRight ? 'right' : 'left',
                    [breakpoints.mb]: {
                        margin: 'auto',
                        paddingTop: '38px',
                        width: '100%',
                        textAlign: 'center',
                    },
                }}
                >
                    <StyledLink
                        to={getLink(link[0].link)}
                    >
                        {link[0].title}
                    </StyledLink>
                </div>
            )}
        </div>
    );
};

export default HoverItemSet;
